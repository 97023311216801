'use client';

import type { ReactNode } from 'react';
import { styled } from 'styled-components';

type FullColumnTextProps = {
  /**
   * Should we display padding at the bottom of this module?
   */
  bottomPadding?: boolean;
  /**
   * Should we display padding at the top of this module?
   */
  topPadding?: boolean;
  /**
   * Module content
   */
  children: ReactNode;
  /**
   * Optional class name, nesc. for extending as a styled-component
   */
  className?: string;
};

const FullColumnContent = styled.div`
  text-align: center;
`;

const FullColumnContainer = styled.div<{
  $bottomPadding: boolean;
  $topPadding: boolean;
}>`
  padding-block: ${({ theme }) => theme.spacing[3]};
  ${({ $bottomPadding }) => !$bottomPadding && `padding-block-end: 0;`}
  ${({ $topPadding }) => !$topPadding && `padding-block-start: 0;`}

  ${({ theme }) => theme.mq.minWidth.large} {
    display: grid;
    ${({ theme }) => theme.grid.columns.large}
    padding-block: ${({ theme }) => theme.spacing[4]};
    ${({ $bottomPadding }) => !$bottomPadding && `padding-block-end: 0;`}
    ${({ $topPadding }) => !$topPadding && `padding-block-start: 0;`}


    ${FullColumnContent} {
      grid-column: 2 / 12;
    }
  }
`;

const FullColumnText = ({
  bottomPadding = true,
  topPadding = true,
  children,
  className,
}: FullColumnTextProps) => (
  <FullColumnContainer
    $bottomPadding={bottomPadding}
    $topPadding={topPadding}
    className={className}
  >
    <FullColumnContent>{children}</FullColumnContent>
  </FullColumnContainer>
);

export default FullColumnText;
