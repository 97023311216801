'use client';

import cs from 'classnames';
import { styled } from 'styled-components';
import * as Styled from '@lib/styles';
import FullColumnText from './FullColumnText';
import Button from '../components/Button';

type CtaProps = {
  /**
   * Optional description for cta
   * NOTE: If this populated the variant will be fullBody
   */
  description?: string;
  /**
   * Optional eyebrow text for the cta
   * NOTE: If this populated the variant will be fullBody
   */
  eyebrowText?: string;
  /**
   * Required! headline for the cta
   */
  headline: string;
  /**
   * Required! Primary cta href
   */
  primaryCtaHref: string;
  /**
   * Required! Primary cta text
   */
  primaryCtaText: string;
  /**
   * Optional tracking label that will tie to a click action
   */
  primaryTrackingLabel?: string;
  /**
   * Optional tracking property that will tie to a click action
   */
  primaryTrackingProperty?: string;
  /**
   * Optional secondary cta href
   * NOTE: If this populated the variant will be fullBody
   */
  secondaryCtaHref?: string;
  /**
   * Optional secondary cta text
   */
  secondaryCtaText?: string;
  /**
   * Optional tracking label that will tie to a click action
   */
  secondaryTrackingLabel?: string;
  /**
   * Optional tracking property that will tie to a click action
   */
  secondaryTrackingProperty?: string;
  /**
   * Color Variant:
   * - light
   * - dark (default)
   */
  colorVariant?: 'dark' | 'light';
};

const CtaContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.blue700};
  border-radius: ${({ theme }) => theme.radius.std};
  color: ${({ theme }) => theme.colors.white};
  margin-block: ${({ theme }) => theme.spacing[3]};

  h2 {
    text-wrap: initial;
  }

  &.light {
    background-color: ${({ theme }) => theme.colors.blue100};
    color: ${({ theme }) => theme.colors.blue700};
  }

  &.fullBody {
    padding-block: ${({ theme }) => theme.spacing[5]};
    padding-inline: ${({ theme }) => theme.spacing[2]};
  }

  &.pill {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-block: ${({ theme }) => theme.spacing[2.5]};
    padding: 2.25rem ${({ theme }) => theme.spacing[2]};
    text-align: center;
  }

  ${({ theme }) => theme.mq.minWidth.large} {
    gap: ${({ theme }) => theme.spacing[1.5]};
    margin-block: ${({ theme }) => theme.spacing[4]};

    &.fullBody {
      padding-block: ${({ theme }) => theme.spacing[5]};
      padding-inline: ${({ theme }) => theme.spacing[4]};
    }

    &.pill {
      border-radius: ${({ theme }) => theme.radius.btn};
      flex-direction: row;
      justify-content: center;
      padding-block: ${({ theme }) => theme.spacing[1.5]};
      padding-inline: ${({ theme }) => theme.spacing[4]};

      h2 {
        margin-bottom: 0;
      }
    }
  }
`;

const CtaFullColumnText = styled(FullColumnText)`
  padding-top: 0;

  ${({ theme }) => theme.mq.minWidth.medium} {
    padding-block-end: ${({ theme }) => theme.spacing[2]};
  }
`;

const CtaButtonRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[1.5]};

  ${({ theme }) => theme.mq.minWidth.large} {
    flex-direction: row;
    justify-content: center;
  }
`;

const Cta = ({
  description,
  eyebrowText,
  headline,
  primaryCtaHref,
  primaryCtaText,
  secondaryCtaHref,
  secondaryCtaText,
  primaryTrackingLabel = null,
  primaryTrackingProperty = null,
  secondaryTrackingLabel = null,
  secondaryTrackingProperty = null,
  colorVariant = 'dark',
}: CtaProps) => {
  const variant =
    description || eyebrowText || secondaryCtaHref ? 'fullBody' : 'pill';

  return (
    <CtaContainer className={cs(variant, colorVariant)}>
      {variant === 'fullBody' ? (
        <>
          <CtaFullColumnText bottomPadding={false}>
            {eyebrowText ? (
              <Styled.Eyebrow>{eyebrowText}</Styled.Eyebrow>
            ) : null}
            <h2>{headline}</h2>
            {description ? <p>{description}</p> : null}
          </CtaFullColumnText>
          <CtaButtonRow>
            <Button
              color="blue500"
              href={primaryCtaHref}
              trackingLabel={primaryTrackingLabel}
              trackingProperty={primaryTrackingProperty}
            >
              {primaryCtaText}
            </Button>
            {secondaryCtaHref ? (
              <Button
                color={colorVariant === 'light' ? 'blue500' : 'white'}
                href={secondaryCtaHref}
                trackingLabel={secondaryTrackingLabel}
                trackingProperty={secondaryTrackingProperty}
                variant="secondary"
              >
                {secondaryCtaText}
              </Button>
            ) : null}
          </CtaButtonRow>
        </>
      ) : (
        <>
          <h2>{headline}</h2>
          <Button
            color="blue500"
            href={primaryCtaHref}
            trackingLabel={primaryTrackingLabel}
            trackingProperty={primaryTrackingProperty}
          >
            {primaryCtaText}
          </Button>
        </>
      )}
    </CtaContainer>
  );
};

export default Cta;
